.container {
  display: block;
  width: 100%;
  overflow: auto;
  padding-left: 6rem;
  padding-top: 4rem;
}

.post {
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
}

.post button {
  margin-right: 1rem;
  margin-left: 1rem;
}
