.container {
  display: block;
  width: 100%;
  overflow: auto;
  padding-top: 4rem;
  padding-bottom: 5rem;
}

.container input, textarea {
  margin-left: auto;
  margin-right: auto;
}

.container label {
  padding-left: 5.5%;
}

.container h1 {
  padding-bottom: 2rem;
  text-align: center;
}

.container h4 {
  margin-left: 5rem;
}

.container button {
  margin-left: auto;
  margin-right: 6%;
}

.inputText {
  padding: 0.5rem;
  padding-right: 0;
  margin-bottom: 0.5rem;
}

.inputText:focus {
  border-color: #3b82f6;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  outline: 0;
}

.post {
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
}

.post button {
  margin-right: 1rem;
  margin-left: 1rem;
}

.preview {
  margin-left: 4rem;
  margin-right: 4rem;
}