.button {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #009688ff;
  color: white;
  box-sizing: border-box;
  transition: 0.1s;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.25rem;
}

.button:hover,
.button:focus {
  outline: none;
  box-shadow: 0 0 0 3px #b2dfdbff, 0 0 0 4px #009688ff;
}

button:disabled {
  opacity: 0.5;
  cursor: wait;
}
