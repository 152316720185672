.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  text-align: center;
  overflow-y: scroll;
  object-fit: contain;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
}

.login {
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  height: 100vh;
  width: 100%;
  padding-top: 3rem;
  overflow-y: scroll;
  object-fit: contain;
}

.forms {
  padding: 1rem;
  margin-bottom: 7rem;
}

.forms h1 {
  text-align: center;
  padding-bottom: 2rem;
}

.forms form {
  padding-top: 3rem;
}

.loginForm {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}

.error {
  color: red;
}

@media (max-width: 40rem) {
  .login {
    grid-template-columns: 1fr;
  }
  .forms {
    max-width: 90%;
  }
}
